
import { PropType, defineComponent, nextTick } from 'vue';
import TimePickerComponent from './_component.vue';
import FormInput from '@/components/form/Input/index.vue';
import { PickerTime } from './index.vue';

export default defineComponent({
  name: 'form-time-picker-range',
  emits: ['update:modelValue', 'update'],
  props: {
    fromHoursItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fromMinutesItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    toHoursItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    toMinutesItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hoursItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    minutesItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    half: {
      type: Boolean,
      default: false,
    },
    fromValue: {
      type: String,
      default: '',
    },
    toValue: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    modalDirection: {
      type: String as PropType<'top' | 'right'>,
      default: 'right',
    },
    shiftId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      from: this.fromValue,
      to: this.toValue,
      focusFrom: true,
      fromPicker: '',
      toPicker: '',
      visible: false,
      value: this.modelValue,
    };
  },
  computed: {
    rangeTime(): string[] {
      const value = this.modelValue.replace(/\s/g, '');

      return value.split('-');
    },
    modifyValue(): { from?: PickerTime; to?: PickerTime } {
      const value = this.value.replace(/\s/g, '');

      if (value.length === 11) {
        const result = value.split('-');
        const from = result[0].split(':');
        const to = result[1].split(':');

        return {
          from: {
            hours: from[0],
            minutes: from[1],
          },
          to: {
            hours: to[0],
            minutes: to[1],
          },
        };
      }

      return {};
    },
  },
  methods: {
    documentClick(e: { target: EventTarget | null }) {
      const el = this.$refs.timePickerRange as HTMLElement;
      const target = e.target;

      if (this.visible && el && el !== target && !el.contains(target as Node)) {
        this.visible = false;
      }
    },
    save() {
      this.value = `${this.from}-${this.to}`;
      const range = this.value.split('-');

      this.$emit('update:modelValue', this.value);
      this.$emit('update', {
        value: this.value,
        from: {
          value: range[0],
          hour: range[0].split(':')[0],
          minute: range[0].split(':')[1],
        },
        to: {
          value: range[1],
          hour: range[1].split(':')[0],
          minute: range[1].split(':')[1],
        },
      });

      this.visible = false;
    },
    handlerSlot(focusFrom: boolean) {
      this.visible = true;
      this.focusFrom = focusFrom;

      nextTick(() => {
        const from: HTMLInputElement = document.querySelector('.time-picker-range__item_from input') as HTMLInputElement;
        const to: HTMLInputElement = document.querySelector('.time-picker-range__item_to input') as HTMLInputElement;

        if (focusFrom) {
          from.select();

          return;
        }

        to.select();
      });
    },
    handlerFocus(event: { target: HTMLInputElement }, focusFrom: boolean) {
      this.focusFrom = focusFrom;

      event.target.select();
    },
    changeFrom(event: { target: HTMLInputElement }) {
      this.from = event.target.value;
    },
    changeTo(event: { target: HTMLInputElement }) {
      this.to = event.target.value;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.from = this.rangeTime[0];
        this.to = this.rangeTime[1];
      },
    },
    fromPicker() {
      this.from = this.fromPicker;
    },
    toPicker() {
      this.to = this.toPicker;
    },
    visible(value: boolean) {
      if (value) {
        this.from = this.rangeTime[0] ?? '';
        this.to = this.rangeTime[1] ?? '';
      }
    },
  },
  mounted() {
    document.body.addEventListener('click', this.documentClick);
  },
  components: {
    TimePickerComponent,
    FormInput,
  },
});
