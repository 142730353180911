
import { defineComponent } from 'vue';
import { VueScrollPicker } from 'vue-scroll-picker';
import './vue-scroll-picker.scss';

export default defineComponent({
  name: 'form-time-picker-component',
  emits: ['update:modelValue'],
  props: {
    hoursItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    minutesItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hoursCurrent: {
      type: String,
    },
    minutesCurrent: {
      type: String,
    },
    half: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hours: this.hoursItems,
      hoursValue: this.hoursCurrent,
      minutes: this.minutesItems,
      minutesValue: this.minutesCurrent,
    };
  },
  methods: {
    initHours() {
      if (this.hours.length) return;

      for (let i = 0; i <= 23; i++) {
        this.hours.push(`${i <= 9 ? '0' : ''}${i}`);
      }
    },
    initMinutes() {
      if (this.minutes.length) return;

      if (this.half) {
        this.minutes.push('00', '30');

        this.minutesValue = this.minutesCurrent ?? '00';

        return;
      }

      for (let i = 0; i <= 59; i++) {
        if (i % 5 === 0) {
          this.minutes.push(`${i <= 9 ? '0' : ''}${i}`);
        }
      }
    },
    scrollTop(list: string, value: string) {
      let model: any = null;
      model = this;

      const currentIndex = model[list].findIndex((item: string) => item === model[value]);

      if (currentIndex <= 0) return;

      model[value] = model[list][currentIndex - 1] as string;
    },
    scrollBottom(list: string, value: string) {
      let model: any = null;
      model = this;

      const currentIndex = model[list].findIndex((item: string) => item === model[value]);

      if (currentIndex >= model[list].length - 1) return;

      model[value] = model[list][currentIndex + 1] as string;
    },
    emit() {
      this.$emit('update:modelValue', this.hoursValue + ':' + this.minutesValue);
    },
    deactivated() {
      const body = document.querySelector('body') as HTMLElement;

      body.classList.remove('cp-open-modal');
    },
  },
  watch: {
    hoursCurrent(value: string) {
      if (value === this.hoursValue) return;

      this.hoursValue = value;
      this.emit();
    },
    minutesCurrent(value: string) {
      if (value === this.minutesValue) return;

      this.minutesValue = value;
      this.emit();
    },
    hoursValue() {
      this.emit();
    },
    minutesValue() {
      this.emit();
    },
    minutesItems(newValue: string[]) {
      this.minutes = newValue;
    },
  },
  mounted() {
    this.initHours();
    this.initMinutes();

    setTimeout(() => {
      document.querySelectorAll('.time-picker-component__hours .vue-scroll-picker-item').forEach((item: Element) => {
        item.addEventListener('click', () => {
          this.hoursValue = item.innerHTML;
        });
      });

      document.querySelectorAll('.time-picker-component__minutes .vue-scroll-picker-item').forEach((item: Element) => {
        item.addEventListener('click', () => {
          this.minutesValue = item.innerHTML;
        });
      });
    });

    const body = document.querySelector('body') as HTMLElement;

    body.classList.add('cp-open-modal');

    this.emit();
  },
  async unmounted() {
    await this.deactivated();
  },
  async deactivated() {
    await this.deactivated();
  },
  components: {
    VueScrollPicker,
  },
});
